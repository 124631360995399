import React from "react";
import { FaMoon, FaSun } from "react-icons/fa";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const Navbar = ({
  toggleDarkMode,
  isDarkMode,
  scrollToSection,
  heroRef,
  serviceRef,
  blogRef,
  contactRef,
}) => {
  return (
    <header className="fixed inset-x-0 top-0 z-30 mx-auto w-full max-w-screen-md border border-gray-100 bg-white/80 py-3 shadow backdrop-blur-lg md:top-6 md:rounded-3xl lg:max-w-screen-xl">
      <div className="px-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center shrink-0">
            <a aria-current="page" className="flex items-center" href="/">
              <img
                className="h-12 w-auto"
                src="./skanda.ico"
                alt="Skanda Marine Solutions Logo"
              />
              <p className="ml-2 text-lg font-semibold text-gray-900">
                Skanda Marine Algae
              </p>
            </a>
          </div>
          <div className="hidden md:flex md:items-center md:justify-center md:gap-8">
            {/* Replace scrollToSection with Link for navigation */}
            <Link
              to="/"
              className="inline-block font-semibold rounded-lg px-2 py-1 text-sm text-gray-900 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
            >
              Home
            </Link>
            <Link
              to="/about"
              className="inline-block rounded-lg px-2 py-1 text-sm font-semibold text-gray-900 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
            >
              About Us
            </Link>
            <Link
              to="/seaweed-cultivation"
              className="inline-block rounded-lg px-2 py-1 text-sm font-semibold text-gray-900 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
            >
              Seaweed Cultivation
            </Link>
            <Link
              to="/blog"
              className="inline-block rounded-lg px-2 py-1 text-sm font-semibold text-gray-900 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
            >
              Blog
            </Link>
            <Link
              to="/contact"
              className="inline-block rounded-lg px-2 py-1 text-sm font-semibold text-gray-900 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
            >
              Contact Us
            </Link>
          </div>
          <div className="flex">
            <div className="hidden pr-16 sm:flex lg:pr-0">
              <button
                onClick={toggleDarkMode}
                className="p-3 bg-gray-200 rounded-full dark:bg-black cursor-pointer"
              >
                {isDarkMode ? (
                  <FaSun className="text-white" />
                ) : (
                  <FaMoon className="text-gray-800" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
