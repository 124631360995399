import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./pages/Footer";
import Navbar from "./components/Navbar";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import Hero from "./pages/Hero";
import Service from "./pages/Services";
import SeaweedCultivation from "./pages/SeaweedCultivation";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("darkMode") === "true"
  );

  const heroRef = useRef(null);
  const serviceRef = useRef(null);
  const blogRef = useRef(null);
  const contactRef = useRef(null);

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark");
      localStorage.setItem("darkMode", "true");
    } else {
      document.body.classList.remove("dark");
      localStorage.setItem("darkMode", "false");
    }
  }, [isDarkMode]);

  return (
    <Router>
      <div className={isDarkMode ? "dark" : "bg-white"}>
        <Navbar
          toggleDarkMode={toggleDarkMode}
          isDarkMode={isDarkMode}
          scrollToSection={scrollToSection}
          heroRef={heroRef}
          serviceRef={serviceRef}
          blogRef={blogRef}
          contactRef={contactRef}
        />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <div ref={heroRef}>
                  <Hero serviceRef={serviceRef} />
                </div>
                {/*
              <div ref={contactRef}>
                <Contact />
              </div>
              <Footer />
              */}
              </>
            }
          />
          {/*
          <Route path="/about" element={
            <>
              <div ref={serviceRef}>
                <Service />
              </div>
              <div ref={blogRef}>
                <Blog />
              </div>
              <div ref={contactRef}>
                <Contact />
              </div>
              <Footer />
            </>
          } />
          <Route path="/seaweed-cultivation" element={
            <>
              <div ref={blogRef}>
                <SeaweedCultivation />
              </div>
              <Footer />
            </>
          } />
          */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
