import React from "react";

const Hero = ({ serviceRef }) => {
  const scrollToService = () => {
    serviceRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="relative bg-white dark:bg-dark">
      <div className="flex flex-wrap">
        <div className="w-full sm:w-7/12 mb-10">
          <div className="container mx-auto h-full sm:p-10">
            <nav className="flex px-4 justify-between items-center sm:pt-7">
              <div className="text-4xl font-bold text-gray-900 dark:text-white">
                Skanda<span className="text-green-700">.</span>
              </div>
              <div>
                <img
                  src="https://image.flaticon.com/icons/svg/497/497348.svg"
                  alt=""
                  className="w-8"
                />
              </div>
            </nav>
            <header className="container px-4 lg:flex mt-14 items-center h-full lg:mt-0">
              <div className="w-full">
                <h1 className="text-4xl lg:text-6xl font-bold text-gray-900 dark:text-white">
                  Explore Sustainability with{" "}
                  <span className="text-green-700">Skanda </span> Marine Algae
                </h1>
                <div className="w-20 h-2 bg-green-700 my-4"></div>
                <p className="text-xl mb-10 text-dark dark:text-white">
                  At Skanda Marine Solutions, our mission is to restore balance
                  in nature through innovative seaweed cultivation initiatives.
                  We empower coastal communities by enabling sustainable
                  practices that benefit the environment and their livelihoods.
                </p>
                <button
                  className="btn btn-wide bg-dark text-white dark:text-black dark:bg-white"
                  onClick={scrollToService}
                >
                  Learn About Us
                </button>
              </div>
            </header>
          </div>
        </div>
        <img
          src="./bg1.jpg"
          alt="sea"
          className="w-full h-56 object-cover sm:h-screen sm:w-5/12"
        />
      </div>
    </div>
  );
};

export default Hero;
